<template lang="pug">
  .basic-settings-container
    .container-fluid
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.basic_settings.status')"
          required
          :required-label="$t('company_system.shop_settings.shop_form.required')"
        )
      .row.basic-settings-form-field.basic-settings-form-field_toggle.d-flex.align-items-center
        AppToggle(
          :disabled="shop.unreleasable"
          :value="shop.released"
          @change="changeReleased"
        )
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.basic_settings.release_date')"
        )
        AppTooltip.info-icon(
          :title="$t('company_system.shop_settings.shop_form.basic_settings.release_date_tooltip')"
        )
      .row.basic-settings-form-field
        .col-md-12.col-lg-9.col-xxl-6
          AppDatepicker(
            :value="releaseDate"
            @change="changeReleaseDate"
            :append-to-body="false"
          )
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.basic_settings.reservation_start_date')"
        )
        AppTooltip.info-icon(
          :title="$t('company_system.shop_settings.shop_form.basic_settings.reservation_start_date_tooltip')"
        )
      .row.basic-settings-form-field
        .col-md-12.col-lg-9.col-xxl-6
          AppDatepicker(
            :value="reservationStartDate"
            @change="changeReservationStartDate"
            :append-to-body="false"
          )
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.basic_settings.store_code')"
        )
      .row.basic-settings-form-field
        .col-md-12.col-lg-9.col-xxl-6
          BFormInput(
            type="text"
            name="code"
            v-model="shop.code"
          )
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.basic_settings.inventory_group')"
          required
          :required-label="$t('company_system.shop_settings.shop_form.required')"
        )
      .row.basic-settings-form-field
        .col-md-12.col-lg-9.col-xxl-6
          AppDropdown.inventory_groups(
            searchable
            close-on-select
            :value="selectedInventoryGroup"
            value-key="id"
            title-key="name"
            :items="inventoryGroups"
            :is-item-disabled="isSharedInventoryEnabled ? isInventoryGroupDisabled : () => false"
            :class="{ invalid: $v.shop.companies_inventory_group_id.$error }"
            @select="selectInventoryGroup"
          )
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.basic_settings.store_name')"
          required
          :required-label="$t('company_system.shop_settings.shop_form.required')"
        )
      .row.basic-settings-form-field
        .col-md-12.col-lg-9.col-xxl-6
          BFormInput(
            type="text"
            name="name"
            v-model="shop.name"
            :class="{ invalid: $v.shop.name.$error }"
            :placeholder="$t('company_system.shop_settings.shop_form.basic_settings.store_name')"
            aria-describedby="name"
          )
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.basic_settings.store_name_furigana')"
          required
          :required-label="$t('company_system.shop_settings.shop_form.required')"
        )
      .row.basic-settings-form-field
        .col-md-12.col-lg-9.col-xxl-6
          BFormInput(
            type="text"
            name="furigana-name"
            v-model="shop.furigana_name"
            :class="{ invalid: $v.shop.furigana_name.$error }"
            :placeholder="$t('company_system.shop_settings.shop_form.basic_settings.store_name_furigana')"
            aria-describedby="furigana-name"
          )
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.basic_settings.pick_up_setting')"
          required
          :required-label="$t('company_system.shop_settings.shop_form.required')"
        )
      .row
        BAlert.warning(
          show
          variant="warning"
          :title="$t('company_system.shop_settings.shop_form.basic_settings.pick_up_setting_explanation')"
        )
          | {{ $t('company_system.shop_settings.shop_form.basic_settings.pick_up_setting_explanation') }}
      .row
        .with-pickup-radio.d-flex.justify-content-left.align-items-center
          AppRadioButton(
            :value="!shop.accept_drop_off"
            @change="handleWithPickUp(false)"
          )
          .label
            span {{ $t('company_system.shop_settings.shop_form.basic_settings.without') }}
      .row.basic-settings-form-field_small
        .with-pickup-radio.d-flex.justify-content-left.align-items-center
          AppRadioButton(
            :value="shop.accept_drop_off"
            @change="handleWithPickUp(true)"
          )
          .label
            span {{ $t('company_system.shop_settings.shop_form.basic_settings.with') }}

      .row(v-show="shop.accept_drop_off")
        .table-label {{ $t('company_system.shop_settings.shop_form.basic_settings.select_vehicle_type_label') }}
        .col-md-12.col-lg-9.col-xxl-6
          .table.d-flex.flex-column
            .table-row.d-flex.align-items-center(
              v-for="category in translatedCarCategopries"
              :key="category.id"
            )
              .table-first-column.d-flex.align-items-center.justify-content-start
                | {{ category.name }}
              .table-second-column.d-flex
                .table-second-column-container.d-flex.align-items-center(
                  v-for="{ id, name } in category.carTypes"
                  :key="id"
                )
                  AppCheckbox(
                    :class="{ invalid: $v.shop.companies_car_type_ids.$error }"
                    :value="isCarTypeSelected(id)"
                    @change="handleCarTypeSelect(id)"
                  )
                  .label
                    | {{ name }}

      .row.label {{ $t('company_system.shop_settings.shop_form.basic_settings.explanation') }}
      .row.basic-settings-form-field
        textarea.form-control(
          v-model="shop.description"
          type="text"
          :rows="3"
          :max-rows="10"
          :maxlength="1024"
          :placeholder="$t('company_system.shop_settings.shop_form.basic_settings.explanation')"
        )
        .textarea-length {{ descriptionLengthLabel }}
    Footer(
      :delete-enabled="isEdit"
      @save="handleSave"
      @delete="$emit('remove-shop')"
    )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withScrollTop from "@/mixins/withScrollTop"

  // stores
  import shopsModule from "@/config/store/company_system/shops_settings/shops/basic_settings"
  import carTypesModule from "@/config/store/company_system/car_class_settings/car_types"
  import inventoryGroupsModule from "@/config/store/company_system/shops_settings/inventory_groups"

  // misc
  import { DESCRIPTION_MAX_LENGTH } from "./constants"
  import { map, find, filter } from "lodash-es"
  import { isSharedInventoryEnabled } from "@/helpers/organization"
  import { extractTranslatedAttribute } from "@/helpers/common"

  const companiesInventoryGroupsMixin = withStoreModule(inventoryGroupsModule, {
    name: "companiesInventoryGroups",
    readers: { inventoryGroups: "items", inventoryGroupsLoading: "loading" },
    actions: { fetchInventoryGroups: "FETCH_ITEMS" }
  })

  const carTypesMixin = withStoreModule(carTypesModule, {
    name: "carTypesMixin",
    readers: { carTypesGrouped: "itemsGrouped" },
    actions: { fetchCarTypesGrouped: "FETCH_ITEMS_GROUPED" }
  })

  const companiesShopsMixin = withStoreModule(shopsModule, {
    resetState: true,
    name: "companiesShops",
    readers: {
      shop: "item",
      shops: "items"
    },
    actions: {
      fetchShop: "FETCH_ITEM",
      createShop: "CREATE_ITEM",
      updateShop: "UPDATE_ITEM",
      deleteShop: "DELETE_ITEM"
    }
  })

  const validationsMixin = withValidations(({ required, requiredIf, minLength }) => ({
    shop: {
      name: {
        required,
        minLength: minLength(1)
      },
      furigana_name: {
        required,
        minLength: minLength(1)
      },
      companies_inventory_group_id: { required },
      companies_car_type_ids: {
        required: requiredIf("accept_drop_off"),
        minLength: minLength(1)
      }
    }
  }))

  export default {
    components: {
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      Footer: () => import("./Footer")
    },

    mixins: [companiesShopsMixin, carTypesMixin, companiesInventoryGroupsMixin, validationsMixin, withScrollTop],

    props: {
      shopId: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        shopLoading: false,
        vehicleTypes: []
      }
    },

    mounted() {
      this.fetchCarTypesGrouped()
      this.fetchInventoryGroups({ pagination: { _disabled: true } })
      if (this.isEdit) {
        this.fetchShop(this.shopId)
      }
    },

    methods: {
      isInventoryGroupDisabled({ id }) {
        return this.selectedInventoryGroupIds.includes(id)
      },

      changeReleased({ value }) {
        this.shop.released = value
      },

      changeReleaseDate(date) {
        this.shop.release_date = date.toString()
      },

      changeReservationStartDate(date) {
        this.shop.reservation_start_date = date.toString()
      },

      textLength(length, maxLength) {
        return `(${length}/${maxLength})`
      },

      selectInventoryGroup({ id }) {
        this.shop.companies_inventory_group_id = id
      },

      isCarTypeSelected(carTypeId) {
        return this.shop.companies_car_type_ids.includes(carTypeId)
      },

      handleCarTypeSelect(id) {
        const index = this.shop.companies_car_type_ids.indexOf(id)

        if (index === -1) {
          this.shop.companies_car_type_ids.push(id)
        } else {
          this.shop.companies_car_type_ids.splice(index, 1)
        }
      },

      translateCarTypes(carTypes) {
        return map(carTypes, carType => {
          return {
            id: carType.id,
            name: extractTranslatedAttribute(carType, "name")
          }
        })
      },

      handleWithPickUp(value) {
        this.shop.accept_drop_off = value
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.$emit("back")
        })
      },

      async sendRequest() {
        const { id, ...shop } = this.shop
        if (this.isEdit) {
          await this.updateShop({ id, shop })
        } else {
          await this.createShop(shop)
        }
      }
    },

    computed: {
      isSharedInventoryEnabled,

      remainingShops() {
        return filter(this.shops, ({ id }) => id !== this.shop.id)
      },

      selectedInventoryGroupIds() {
        return map(this.remainingShops, "inventory_group_id")
      },

      releaseDate({ shop: { release_date } }) {
        return release_date ? new Date(release_date) : undefined
      },

      reservationStartDate({ shop: { reservation_start_date } }) {
        return reservation_start_date ? new Date(reservation_start_date) : undefined
      },

      isEdit() {
        return !!(this.shopId || this.shop.id)
      },

      translatedCarCategopries() {
        return map(this.carTypesGrouped, carCategory => {
          return {
            id: carCategory.id,
            name: extractTranslatedAttribute(carCategory, "name"),
            carTypes: this.translateCarTypes(carCategory.car_types)
          }
        })
      },

      selectedInventoryGroup() {
        return find(this.inventoryGroups, { id: this.shop.companies_inventory_group_id })
      },

      descriptionLength() {
        return this.shop.description?.length || 0
      },

      descriptionLengthLabel({ descriptionLength }) {
        return this.textLength(descriptionLength, DESCRIPTION_MAX_LENGTH)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/mixins/company-system.sass"

  .basic-settings-container
    .row
      &.label
        +form-label

      .warning
        padding: 5px 7px
        margin-bottom: 7px

      ::v-deep
        .required
          margin-left: 8px
        > div
          padding: 0

        .form-control
          border-color: #ccc

    .basic-settings-form-field
      margin-top: 5px
      margin-bottom: 24px

      ::v-deep
        input
          &.invalid
            +default-invalid-input

      &_small
        margin-bottom: 8px

      .app-select
        padding: 0

        ::v-deep
          .dropdown-toggle
            height: 38px
            border-color: #ccc

      .datepicker
        width: 100%
        padding-right: 0
        ::v-deep
          .mx-input-wrapper
            &:hover
              .mx-icon-calendar
                display: initial

          .mx-input
            padding-left: 30px
            padding-right: 10px
            border-color: #ccc
          .mx-icon-calendar
            left: 8px
          .mx-datepicker-popup
            left: 0 !important

      // Disable arrows in number inputs
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

    .with-pickup-radio
      .label
        margin-left: 8px
    .table
      .table-row
        height: 48px
      .table-first-column
        background-color: $border-element-color
        height: 100%
        padding-left: 12px
        flex: 1 0 30%
      .table-second-column
        flex: 1 1 70%
        &-container
          margin-right: 12px
      ::v-deep
        .app-checkbox
          padding-right: 0

          &.invalid
            .app-checkbox-icon
              +default-invalid-input

    .credit-card
      margin-right: 12px

      ::v-deep
        .app-checkbox
          padding: 0
          &-icon
            margin-right: 8px
    .other-card-field
      .other-card-input
        margin-left: 8px
        flex: 1 0 120px

    .textarea-length
      font-size: 12.5px
      font-weight: 500
      color: $default-gray
</style>
